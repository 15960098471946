<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Laporan Pendapatan Apotik <b>Klinik Hayandra</b>
      </div>
    </b-alert>

    <div class="row">

      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <b-tabs content-class="mt-3">
                <b-tab
                  title="Pendapatan Apotik"
                  active
                >
                  <div class="mt-6"></div>
                  <PharmacyReportTable 
                  @modalShow="modalShow"
                  @dataModal="dataModalShow"
                  />
                </b-tab>
                <b-tab title="Pendapatan Obat Klinik">
                  <div class="mt-6"></div>
                  <MedicineTable 
                  @modalShow="modalShow"
                  @dataModal="dataModalShow"
                  />
                </b-tab>
                <b-tab title="Pendapatan Obat Toko Online">
                  <div class="mt-6"></div>
                  <EcommerceTable 
                  @modalShow="modalShow"
                  @dataModal="dataModalShow"
                  />
                </b-tab>
              </b-tabs>
          </template>
        </Card>
      </div>

    </div>
    <ModalReport 
      :reportType="reportType"
      :reportName="reportName"
      :dataModal="dataModal"
      :excel="dataExcel"
    />
  </div>
</template>

<script>

import Card from '@/view/content/Card.vue'
import Table from '@/component/reports/PayrollReport.vue'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"
import MedicineTable from "@/component/reports/PaymentPharmacyReport.vue"
import EcommerceTable from "@/component/reports/EcommerceRecordReport.vue"
import PharmacyReportTable from "@/component/reports/PharmacyReport.vue"
import ModalReport from "@/component/general/ModalReport.vue"
export default {

  components: {
    Card,
    Table,
    MedicineTable,
    EcommerceTable,
    PharmacyReportTable,
    ModalReport,
   
  },
  data(){
    return{
      reportType:null,
      reportName:null,

      dataModal:[],
      dataExcel:[],

    }
  },
  methods:{
    modalShow(type,name){
      this.reportType = type
      this.reportName = name
      
      this.$bvModal.show('modal-report')
      // this.dataModal()
    },
    dataModalShow(dataModal,excel){
      this.dataModal = dataModal
      this.dataExcel = excel

      console.log(this.dataModal,this.dataExcel,"dump 2");
    }
  },
  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Laporan", route: "" },
      { title: "Laporan Pendapatan Apotik" },
    ])
  },

}
</script>

<style>
</style>