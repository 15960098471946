<template>
  <div>
    <!-- Filter -->
    <div class="row align-items-center justify-content-start mt-2">
      <div class="col-md-6 col-12">
        <button class="btn mx-1 btn-timeseries" style="padding-left : 5px; padding-right : 5px; padding-top : 7px; padding-bottom : 7px;" @click="btnActiveOnClick('days')" v-bind:class="{ 'btn-info': btnActive.days, 'btn-outline-info': btnActive.days == false }">Hari Ini</button>
        <button class="btn mx-1 btn-timeseries" style="padding-left : 5px; padding-right : 5px; padding-top : 7px; padding-bottom : 7px;" @click="btnActiveOnClick('month')" v-bind:class="{ 'btn-info': btnActive.month, 'btn-outline-info': btnActive.month == false }">Bulan Ini</button>
        <button class="btn mx-1 btn-timeseries" style="padding-left : 5px; padding-right : 5px; padding-top : 7px; padding-bottom : 7px;" @click="btnActiveOnClick('lastMonth')" v-bind:class="{ 'btn-info': btnActive.lastMonth, 'btn-outline-info': btnActive.lastMonth == false }">Bulan Kemarin</button>
        <button class="btn mx-1 btn-timeseries" style="padding-left : 5px; padding-right : 5px; padding-top : 7px; padding-bottom : 7px;" @click="btnActiveOnClick('year')" v-bind:class="{ 'btn-info': btnActive.year, 'btn-outline-info': btnActive.year == false }">Tahun Ini</button>
        <button class="btn mx-1 btn-timeseries" style="padding-left : 5px; padding-right : 5px; padding-top : 7px; padding-bottom : 7px;" v-bind:class="{ 'btn-info': btnActive.period, 'btn-outline-info': btnActive.period == false }" @click="btnPeriodeOnClick">Periode</button>
      </div>
      <div class="col-md-2">
        <treeselect
          v-model="filter.doctor_id"
          :multiple="false"
          :options="doctors"
          :clearable="true"
          placeholder="Cari Berdasar Dokter" />
      </div>

      <div class="col-md-3">
        <b-input-group>
          <template #prepend>
            <b-input-group-text squared>
              <b-icon-search></b-icon-search>
            </b-input-group-text>
          </template>
          <b-form-input
            type="text"
            v-model="filter.patient_name"
            placeholder="Cari Nama Pasien"
            @keyup="filterByPatient"></b-form-input>
        </b-input-group>
      </div>
      <div class="col-md-1">
        <b-form-select
          id="input-period"
          v-model="perPage"
          :options="formats"
          @change="perPageNumber"
          placeholder="Per Halaman (Def. 20)"></b-form-select>
      </div>
    </div>

    <div class="row justify-content-between align-items-center mt-5">
      <div class="col-md-6" v-if="onShowPeriod">

        <b-input-group>
          <template #prepend>
            <b-button
              squared
              @click="resetFilterPeriode"
              variant="danger">Reset</b-button>
            <b-button
              squared
              @click="filterByPeriode"
              variant="success">Cari</b-button>
          </template>
          <b-form-input
            v-model="filter.start_date"
            type="text"
            placeholder="Tanggal Awal"
            autocomplete="off"
            readonly></b-form-input>
          <b-input-group-append>
            <b-form-datepicker
              v-model="filter.start_date"
              button-only
              right
              reset-button
              locale="id"></b-form-datepicker>
          </b-input-group-append>
          <b-button
            squared
            variant="light">s/d</b-button>
          <b-form-input
            v-model="filter.end_date"
            type="text"
            placeholder="Tanggal Akhir"
            autocomplete="off"
            readonly></b-form-input>
          <b-input-group-append>
            <b-form-datepicker
              v-model="filter.end_date"
              button-only
              right
              reset-button
              locale="id"></b-form-datepicker>
          </b-input-group-append>
        </b-input-group>

      </div>
      <div class="col-md-6" v-if="onShowPeriod == false"></div>

      <div class="col-md-4 d-flex justify-content-end">
        <button
          class="btn btn-success mr-1"
          v-b-tooltip.hover
          title="Grafik"
          @click="$router.push('/report/pharmacy-chart')">Grafik <i class="fas fa-chart-bar px-0 ml-1"></i></button>
        <button
          class="btn btn-primary mr-1"
          @click="btnExportOnClick">
          Ekspor <i class="fas fa-print px-0 ml-1"></i>
        </button>
      </div>

    </div>

    <div
      class="row justify-content-between align-items-center mt-6"
      v-if="dataLoad == true">
      <div class="col-md-4">
        <span
          style="width: 150px; color: #a12260 !important"
          class="font-weight-bold">{{ recordData }} Baris Data</span>
      </div>

      <div class="col-md-6 d-flex justify-content-end">
        <span
          style="width: 150px; color:#a12260 !important;"
          class="font-weight-bolder">Total :</span>
        <span
          class="font-weight-bolder"
          style="color:#a12260 !important;">{{ "Rp. " + parseInt(summary).toLocaleString("id-ID") }}</span>
      </div>

    </div>

    <!-- Table -->
    <b-table
      class="mt-3"
      striped
      hover
      :items="items"
      :fields="fields"
      :style='"white-space: nowrap"'
      responsive>
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: field.key === 'actions' ? '10%' : '' }" />
      </template>
      <template #cell(product_list)="data">
        <div
          v-for="(content, index) in data.item.detail"
          :key="index">
          <li>
            {{ `${content.medicine_name} : ${content.quantity} X ${parseInt(content.amount).toLocaleString('id-ID')}` }}
          </li>
        </div>
      </template>
      <template #cell(surcharge)="data">
        {{ "Rp " + parseInt(data.item.surcharge).toLocaleString("id-ID") }}
      </template>
      <template #cell(final_amount)="data">
        {{ "Rp " + parseInt(data.item.final_amount).toLocaleString("id-ID") }}
      </template>
    </b-table>
    <b-pagination
      v-if="items.length != 0"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @page-click="pageOnClick"
      class="mt-4"></b-pagination>


    <!-- PDF -->
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :filename="'Laporan Pendapatan Apotik'"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="780px"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf">
      <section slot="pdf-content">
        <template>
          <div id="printPharmacy">
            <div class="d-flex justify-content-center align-items-center mt-3">
              <h5>Laporan Pendapatan Apotik</h5>
            </div>

            <div
              class="d-flex justify-content-end align-items-center mt-6">
              <span
                style="width: 150px"
                class="font-weight-bolder">Total :</span>
              <!-- <span class="font-weight-bold">{{ typeof summary[0] != 'undefined' ? "Rp. " + parseInt(summary[0].final_amount).toLocaleString("id-ID") : '' }}</span> -->
              <span class="font-weight-bolder">{{ "Rp. " + parseInt(summary).toLocaleString("id-ID") }}</span>
              <!-- <span class="font-weight-bold">{{ summary}}</span> -->
            </div>

            <b-table
              class="mt-3"
              striped
              hover
              :items="items"
              :fields="fieldsPrint">
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'actions' ? '10%' : '' }" />
              </template>
              <template #cell(product_list)="data">
                <div
                  v-for="(content, index) in data.item.detail"
                  :key="index">
                  <li>
                    <span class="text-sizing">
                      {{ `${content.medicine_name} : ${content.quantity} X ${parseInt(content.amount).toLocaleString('id-ID')}` }}

                    </span>
                  </li>
                </div>
              </template>
              <template #cell(surcharge)="data">
                <span class="text-sizing">
                  {{
                    "Rp " + parseInt(data.item.surcharge).toLocaleString("id-ID")
                  }}

                </span>
              </template>
              <template #cell(final_amount)="data">
                <span class="text-sizing">
                  {{
                    "Rp " +
                    parseInt(data.item.final_amount).toLocaleString("id-ID")
                  }}

                </span>
              </template>
            </b-table>
          </div>
        </template>
      </section>
    </vue-html2pdf>
    <!-- <ModalReport 
      :reportType="'pharmacy-report'"
      :reportName="'Pendapatan Apotek'"
    /> -->
  </div>
</template>

<script>

import XLSX from "xlsx"
import VueHtml2pdf from "vue-html2pdf"
import { debounce } from '@/core/modules/Helper.js'
import module from "@/core/modules/CrudModule.js"
import validation from "@/core/modules/ValidationModule.js"
import Treeselect from "@riophae/vue-treeselect"
import "@riophae/vue-treeselect/dist/vue-treeselect.css"
import Card from "@/view/content/Card.vue"
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"
import MedicineTable from "@/view/pages/reports/PharmacyHospitalReport.vue"
import EcommerceTable from "@/view/pages/reports/EcommerceReport.vue"
import ModalReport from "@/component/general/ModalReport.vue"
import moment from 'moment';

export default {
  components: {
    Card,
    Treeselect,
    VueHtml2pdf,
    MedicineTable,
    EcommerceTable,
    ModalReport,
  },

  data() {
    return {
      // Filter
      filter: {
        doctor_id: "",
        patient_name: "",
        start_date: "",
        end_date: "",
      },
      // filter timeseries
      onShowPeriod: false,
      btnActive: {
        days: true,
        month: false,
        lastMonth: false,
        year: false,
        period: false,
      },
      lastPeriodType: "days",
      // Options
      doctors: [],
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      recordData: 0,
      // Table Header
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "code",
          label: "Kode",
          sortable: false,
        },
        {
          key: "display_transaction_date",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "doctor_name",
          label: "Dokter",
          sortable: false,
        },
        {
          key: "patient_name",
          label: "Pasien",
          sortable: false,
        },
        {
          key: "product_list",
          label: "Daftar Produk",
          sortable: true,
        },
        {
          key: "final_amount",
          label: "Total",
          sortable: true,
        },
        {
          key: "surcharge",
          label: "Surcharge",
          sortable: true,
        },
        {
          key: "notes",
          label: "Catatan",
          sortable: true,
        },
      ],
      fieldsPrint: [
        {
          key: "number",
          label: "#",
          thClass: "text-sizing",
          tdClass: "text-sizing"
        },
        {
          key: "code",
          label: "Kode",
          thClass: "text-sizing",
          tdClass: "text-sizing"
        },
        {
          key: "display_transaction_date",
          label: "Tanggal",
          thClass: "text-sizing",
          tdClass: "text-sizing"
        },
        {
          key: "doctor_name",
          label: "Dokter",
          thClass: "text-sizing",
          tdClass: "text-sizing"
        },
        {
          key: "patient_name",
          label: "Pasien",
          thClass: "text-sizing",
          tdClass: "text-sizing"
        },
        {
          key: "product_list",
          label: "Daftar Produk",
          thClass: "text-sizing",
          tdClass: "text-sizing"
        },
        {
          key: "final_amount",
          label: "Total",
          thClass: "text-sizing",
          tdClass: "text-sizing"
        },
        {
          key: "surcharge",
          label: "Surcharge",
          thClass: "text-sizing",
          tdClass: "text-sizing"
        },
        {
          key: "notes",
          label: "Catatan",
          thClass: "text-sizing",
          tdClass: "text-sizing"
        },
      ],
      formats: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
        { text: "300", value: 300 },
      ],
      // Table Items
      items: [],
      summary: 0,
      dataLoad: false,
      excelData: [],
      dataModal: [],

    };
  },

  methods: {

    perPageNumber() {
      this.pagination()
    },

    pageOnClick(evt, page) {
      evt.preventDefault()
      this.currentPage = page
      this.pagination()
    },

    async pagination() {
      let filterParams = `&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}&doctor_id=${this.filter.doctor_id}&patient_name=${this.filter.patient_name}`;
      // let filterReportParams = `start_date=${this.filter.start_date}&end_date=${this.filter.end_date}&doctor_id=${this.filter.doctor_id}&patient_name=${this.filter.patient_name}`;
      let response = await module.paginate("pharmacy-reports", `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`);
      // let responseExcel = await module.get("pharmacy-excel-reports", `?${filterReportParams}`);
      // let responseReport = await module.get("pharmacy-no-paginations", `?${filterReportParams}`);
      // this.summary = await module.get("pharmacy-reports-summary-amount", `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`);
      let pagination = response.meta.pagination;

      // this.excelData = responseExcel
      // this.dataModal = responseReport

      // this.setDataModal(this.dataModal,this.excelData)

      this.items = response.data;
      this.totalRows = pagination.total;
      this.recordData = pagination.total;

      this.dataLoad = true;

      let a
      this.summary = 0
      for (a = 0; a < this.items.length; a++) {
        this.summary += parseInt(this.items[a].final_amount)
      }
      // console.log("summary",this.summary);

    },

    async btnExportOnClick() {
      let filterReportParams = `start_date=${this.filter.start_date}&end_date=${this.filter.end_date}&doctor_id=${this.filter.doctor_id}&patient_name=${this.filter.patient_name}`;

      let responseExcel = await module.get("pharmacy-excel-reports", `?${filterReportParams}`);
      let responseReport = await module.get("pharmacy-no-paginations", `?${filterReportParams}`);
      // this.summary = await module.get("pharmacy-reports-summary-amount", `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`);

      this.excelData = responseExcel
      this.dataModal = responseReport

      this.setDataModal(this.dataModal, this.excelData)
      this.showModal('pharmacy-report', 'Pendapatan Apotek')

    },

    async getDoctorsOption() {
      let response = await module.setTreeSelect("doctors");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.doctors = response.data;
        this.doctors.unshift({ label: "Cari Berdasar Dokter", id: "", isDisabled: true });
      }
    },

    resetFilter() {
      this.filter.start_date = ""
      this.filter.end_date = ""
      this.filter.doctor_id = ""
    },

    resetFilterPeriode() {
      this.filter = validation.clearForm(this.filter)
      this.btnActiveOnClick(this.lastPeriodType)
      this.pagination()
    },

    async filterByPeriode() {
      this.pagination();
    },

    async filterByPatient() {
      debounce(() => {
        // Reset Other Filter
        this.filter.start_date = ""
        this.filter.end_date = ""
        if (this.filter.patient_name == '') {
          this.btnActiveOnClick(this.lastPeriodType)
        }
        this.pagination()
      }, 500)
    },

    async filterByDoctor(evt) {
      this.filter.doctor_id = evt.id;
      this.pagination();
    },

    btnPrintOnClick() {
      this.$htmlToPaper('printPharmacy')
    },

    btnDownloadOnClick() {
      this.$refs.html2Pdf.generatePdf()
    },

    onProgress($event) {
      console.log("onProgress", $event)
    },

    hasStartedGeneration() {
      console.log("hasStartedGeneration")
    },

    hasGenerated($event) {
      console.log("hasGenerated", $event)
    },

    btnExcelOnClick() {
      const data = XLSX.utils.json_to_sheet(this.excelData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "Laporan Pendapatan Apotik.xlsx");
    },

    showModal(type, name) {
      this.$emit('modalShow', type, name)
      // console.log(this.dataModal,this.excelData,"dump")
      this.setDataModal(this.dataModal, this.excelData)
    },
    setDataModal(dataModal, excel) {
      this.$emit('dataModal', dataModal, excel)
    },

    btnPeriodeOnClick() {
      if (this.onShowPeriod == false) {
        this.onShowPeriod = true
        this.btnActiveOnClick('period')
      } else {
        this.onShowPeriod = false
        this.btnActiveOnClick('period')
      }
    },

    btnActiveOnClick(type) {
      if (type == 'days') {
        // set time
        this.filter.start_date = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.filter.end_date = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')

        // get data
        this.pagination()

        // set btn Active
        this.btnActive.days = true
        this.btnActive.month = false
        this.btnActive.lastMonth = false
        this.btnActive.year = false
        this.btnActive.period = false

        this.lastPeriodType = "days"

        // hide period
        this.onShowPeriod = false
      } else if (type == 'month') {
        // set time
        this.filter.start_date = moment().startOf('month').startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.filter.end_date = moment().endOf('month').endOf('day').format('YYYY-MM-DD HH:mm:ss')

        // get data
        this.pagination()

        // set btn Active
        this.btnActive.days = false
        this.btnActive.month = true
        this.btnActive.lastMonth = false
        this.btnActive.year = false
        this.btnActive.period = false

        this.lastPeriodType = "month"

        // hide period
        this.onShowPeriod = false
      } else if (type == 'lastMonth') {
        // set time
        this.filter.start_date = moment().subtract(1, 'months').startOf('month').startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.filter.end_date = moment().subtract(1, 'months').endOf('month').endOf('day').format('YYYY-MM-DD HH:mm:ss')


        // get data
        this.pagination()

        // set btn Active
        this.btnActive.days = false
        this.btnActive.month = false
        this.btnActive.lastMonth = true
        this.btnActive.year = false
        this.btnActive.period = false

        this.lastPeriodType = "lastMonth"

        // hide period
        this.onShowPeriod = false
      } else if (type == 'year') {
        // set time
        this.filter.start_date = moment().startOf('year').startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.filter.end_date = moment().endOf('year').endOf('day').format('YYYY-MM-DD HH:mm:ss')

        // get data
        this.pagination()

        // set btn Active
        this.btnActive.days = false
        this.btnActive.month = false
        this.btnActive.lastMonth = false
        this.btnActive.year = true
        this.btnActive.period = false

        this.lastPeriodType = "year"

        // hide period
        this.onShowPeriod = false
      } else if (type == 'period') {
        // set time
        this.filter.start_date = ""
        this.filter.end_date = ""

        // set btn Active
        this.btnActive.days = false
        this.btnActive.month = false
        this.btnActive.lastMonth = false
        this.btnActive.year = false
        this.btnActive.period = true
      }
    },

  },


  watch: {
    "filter.doctor_id": function (newVal, oldVal) {
      this.filter.doctor_id = typeof newVal === "undefined" ? "" : newVal;
      this.pagination();
    },
  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Laporan", route: "" },
      { title: "Laporan Pendapatan Apotik" },
    ])
    this.pagination()
    this.getDoctorsOption()
  },

}
</script>

<style>
.text-sizing {
  font-size: 10px;
}
</style>